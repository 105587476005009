/* 
// 09. Counter
*/

// .counter-box {
//     .counter-icon {
//         background: rgba($white, 0.02);
//     height: 90px;
//     width: 90px;
//     border-radius: 50%;
//     line-height: 90px;
//     text-align: center;
//     font-size: 46px;
//     box-shadow: 0 3px 0px rgba($white, 0.20);
//     }
// }
.counter-box .counter-icon {
    background: rgba(255,255,255,0.02);
    height: 90px;
    width: 90px;
    border-radius: 50%;
    line-height: 90px;
    text-align: center;
    font-size: 46px;
    -webkit-box-shadow: 0 3px 0px rgba(255,255,255,0.2);
    box-shadow: 0 3px 0px rgba(255,255,255,0.2);
}
.text-opacity{
    opacity: 0.9!important;
}